.duration-item {
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
}

.full-cycle {
    background: lightgreen;    
}

.on-going {
    background: orange;
}
