@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
@charset "UTF-8";

/* Externa imports (i vårt fall typsnitt) */

/* Reset */
html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Main */

body {
    background-image: url(/webapp/static/media/bg.1214690b.jpg);
    font-family: 'Lato', sans-serif;
    font-size: 1.8rem; /* =16px */
    line-height: 1.5;
}

header {
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
}

#root {
    background-color: white;
    min-height: 100vh;
}

nav {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    background-color: #eee;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
}

nav a {
    color: #000;
}

.duration-prev-next-btns {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 1.5rem;
    text-align: center;
}

.duration-prev-next-btns h2:first-letter{
    text-transform: capitalize
}

.duration-prev-next-btns button {
    background-color: orange;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    font-size: 1.5rem;
    padding: 1rem;
    border: 0;
    border-radius: 0.5rem;
    transition: 0.2s all;
}

.duration-prev-next-btns button:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}


.duration-list {
    background-color: #eee;
    border-radius: 0.5rem;
    -webkit-flex-basis: 300px;
            flex-basis: 300px;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0.5rem;
    margin: 1.5rem;
}

.duration-item {
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
}

.full-cycle {
    background: lightgreen;    
}

.on-going {
    background: orange;
}

.events-list {
    background-color: #eee;
    border-radius: 0.5rem;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0.5rem;
    margin: 1.5rem;
}

.events-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
}

.went-out {
    background-color: indianred;
}

.went-in {
    background-color: white;
}

header {
    text-align: center;
}

#root {
    max-width: 1000px;
    margin: 0 auto;
}

.DurationsListsContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

