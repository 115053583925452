@charset "UTF-8";

/* Externa imports (i vårt fall typsnitt) */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

/* Reset */
html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Main */

body {
    background-image: url("./bg.jpg");
    font-family: 'Lato', sans-serif;
    font-size: 1.8rem; /* =16px */
    line-height: 1.5;
}

header {
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
}

#root {
    background-color: white;
    min-height: 100vh;
}
