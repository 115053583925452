.duration-prev-next-btns {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;
    text-align: center;
}

.duration-prev-next-btns h2:first-letter{
    text-transform: capitalize
}

.duration-prev-next-btns button {
    background-color: orange;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    font-size: 1.5rem;
    padding: 1rem;
    border: 0;
    border-radius: 0.5rem;
    transition: 0.2s all;
}

.duration-prev-next-btns button:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

