.events-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
}

.went-out {
    background-color: indianred;
}

.went-in {
    background-color: white;
}
