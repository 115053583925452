nav {
    display: flex;
    justify-content: space-around;
    background-color: #eee;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
}

nav a {
    color: #000;
}
