header {
    text-align: center;
}

#root {
    max-width: 1000px;
    margin: 0 auto;
}

.DurationsListsContainer {
    display: flex;
    flex-wrap: wrap;
}
